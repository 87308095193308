<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-2">
      <h4 class="text-center text-h4 mb-2">Registrar un examen</h4>

      <v-row class="mx-5 mx-lg-16 px-lg-16" justify="center">
        <v-col cols="12" md="">
          <v-form ref="form" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Datos básicos</h6>

            <label for="" class="ml-4 black--text font-weight-medium">Id</label>
            <v-text-field
              placeholder="Id"
              color="black"
              v-model="test.id"
              rounded
              :rules="[rules.required, rules.alfanumeric]"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombre</label
            >
            <v-text-field
              placeholder="Nombre"
              color="black"
              v-model="test.name"
              rounded
              :rules="[rules.required, rules.alfanumeric]"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Tipo de muestra</label
            >
            <v-text-field
              placeholder="Precio"
              color="black"
              rounded
              v-model="test.sampleType"
              :rules="[rules.required, rules.alfanumeric]"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Descripción</label
            >
            <v-textarea
              placeholder="Descripción"
              color="black"
              rounded
              :rules="[rules.alfanumeric]"
              v-model="test.description"
              solo
              rows="2"
              class="mt-2 mb-n3"
            ></v-textarea>
          </v-form>
        </v-col>
        <v-divider vertical class="my-8 mx-lg-16 d-md-block d-none"></v-divider>

        <v-col cols="12" md="">
          <v-form ref="form2" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Información adicional</h6>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombre en resultado</label
            >
            <v-text-field
              placeholder="Nombre"
              color="black"
              v-model="test.resultName"
              rounded
              :rules="[rules.alfanumeric]"
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Precio</label
            >
            <v-text-field
              placeholder="Precio"
              color="black"
              rounded
              v-model="test.price"
              :rules="[rules.required, rules.number]"
              required
              solo
              class="mt-2 mb-n3 font-work-sans"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Categoría</label
            >
            <v-select
              ref="info"
              placeholder="Seleccione una categoría"
              :rules="[rules.required]"
              v-model="test.testCategoryId"
              :items="categories"
              solo
              rounded
              class="mt-2 mb-n3"
            ></v-select>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Equipo</label
            >
            <v-text-field
              placeholder="Equipo"
              color="black"
              v-model="test.equipment"
              rounded
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Información extra</label
            >
            <v-textarea
              placeholder="Información extra"
              color="black"
              rounded
              v-model="test.extraInfo"
              solo
              rows="2"
              class="mt-2 mb-n3"
            ></v-textarea>

            <div class="d-flex align-center justify-space-between mt-n2 mb-5">
              <label for="" class="ml-4 black--text font-weight-medium"
                >Ver en catálogo</label
              >
              <v-switch
                color="primary"
                class="mt-2 mb-n3"
                required
                outlined
                v-model="test.isVisibleInCatalog"
              ></v-switch>
            </div>
          </v-form>
        </v-col>
      </v-row>

      <div
        class="d-flex flex-column flex-md-row align-center justify-end mx-md-16"
      >
        <router-link custom v-slot="{ navigate }" :to="{ name: 'Test' }">
          <v-btn
            color="secondary"
            outlined
            class="rounded-lg mr-md-4 my-2 my-md-0"
            @click="navigate"
          >
            <v-icon>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
        </router-link>

        <v-btn
          color="primary"
          class="rounded-lg ml-md-4 my-2 my-md-0 elevation-0"
          @click="submit"
          :loading="loading"
        >
          <v-icon>fa-test-plus</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
const TestRepository = Repository.get('tests')
const CategoryRepository = Repository.get('categories')

export default {
  name: 'CreateTest',
  data: () => ({
    valid: false,
    show: false,
    loading: false,
    test: {
      id: '',
      name: '',
      resultName: '',
      description: '',
      price: '',
      sampleType: '',
      testCategoryId: '',
      equipment: '',
      extraInfo: '',
    },
    categories: [],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      number: (value) => {
        const pattern = /^[]?([0-9]+\.?[0-9]*|\.[0-9]+)$/
        return pattern.test(value) || 'Campo inválido.'
      },
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      try {
        const res = await CategoryRepository.get()
        this.categories = res.data.data.items
        this.categories = Array.from(this.categories, (item) => ({
          text: item.name,
          value: item.id,
        }))
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    submit: async function () {
      this.$refs.form.validate()
      this.$refs.form2.validate()

      setTimeout(async () => {
        if (this.valid) {
          await this.create()
        }
      }, 500)
    },

    create: async function () {
      this.loading = true
      try {
        const res = await TestRepository.create(this.test)

        const data = {
          type: 'success',
          title: 'Petición exitosa',
          text: res.data.message,
        }

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$router.back()
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
      this.loading = false
    },
  },
}
</script>
